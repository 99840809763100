.section_1_homepage {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    min-height: calc(200vh);
    min-width: 100vw;
    flex-direction: column;
    overflow: hidden;
}

.section_1_homepage .bg {
    display: flex;
    position: absolute;
    top: calc(3rem + 56px);
    left: 0;
    z-index: 9;
    width: 100%;
}

.section_1_homepage .bg1_div {
    background-color: white;
    flex-grow: 1;
    height: calc(100vh + 100px - 3rem - 56px);
    overflow: hidden;
}

.section_1_homepage .bg_1 {
    height: 100%;
}

.section_1_homepage .bg2_div {
    height: calc(100vh + 100px - 3rem - 56px);
    display: flex;
    flex-direction: column;
}

.section_1_homepage .bg2_div div {
    height: 100px;
    width: 100%;
    background-color: white;
}

.section_1_homepage .bg_2 {
    height: calc(100vh - 3rem - 56px);
}

.section_1_homepage .title p {
    margin: 0;
}

.section_1_homepage .title_div {
    position: absolute;
    bottom: -5rem;
    left: 0;
    width: 100%;
}

.section_1_homepage .title .title1 {
    color: var(--black, #000);
    text-shadow: 0px 0px 105px #fff;
    font-family: "Monument Extended";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
}

.section_1_homepage .title .title2 {
    background: var(--S-1,
            linear-gradient(46deg, #0092bd 0.24%, #00c67a 97.74%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Monument Extended";
    font-size: 43px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    padding-bottom: 30px;
}

.section_1_homepage .title .title3 {
    color: var(--White, #fff);
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    width: 50%;
    text-shadow: 1px 2px 2px black;
    border-radius: 24px;
    background: linear-gradient(101deg,
            #152a31 21%,
            rgba(29, 29, 29, 0) 113.44%);
    padding: 2rem;
}

.section_1_homepage .bgvideo {
    order: 0;
    place-self: auto;
    grid-area: auto;
    z-index: 1;
    float: none;
    flex-shrink: 1;
    display: block;
    margin: 0px;
    inset: 0px;
    flex-basis: auto;
    overflow: visible;
    box-sizing: border-box;
    width: 100vw;
    height: 200vh;
    padding: 0px;
    position: absolute;
    top: 0;
    left: 0;
}

.section_1_homepage .bgvideo .video {
    translate: none;
    rotate: none;
    scale: none;
    inset: 0px auto auto 0px;
    margin: 0px;
    max-width: 100vw;
    width: 100vw;
    max-height: 200vh;
    height: 200vh;
    padding: 0px;
}

.section_1_homepage .bgvideo .video video {
    height: 100%;
    object-fit: fill;
    width: 100%;
}

.section_1_homepage .title {
    height: fit-content;
    margin-left: 4rem;
    z-index: 9;
    position: relative;
}

.section_1_homepage .title_parent {
    width: 100%;
    height: 100%;
    z-index: 9;
    position: fixed;
    top: 50vh;
}

.section_1_homepage .title .btn {
    width: fit-content;
    display: flex;
    padding: 10px 20px;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid var(--Dark-primary, #152a31);
    background: #fff;
    box-shadow: -4px 4px 16px 0px rgba(0, 0, 0, 0.1);
    z-index: 2;
    position: relative;
    cursor: pointer;
    transition: 0.5s;
}

.section_1_homepage .title .btn:hover {
    background: #152a31;
}

.section_1_homepage .title .btn svg {
    width: 24px;
    height: 24px;
}

.section_1_homepage .title .btn:hover svg path {
    fill: white;
}

.section_1_homepage .title .btn span {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.section_1_homepage .title .btn:hover span {
    color: white;
}

.section_1_homepage .section_1_footer {
    border-radius: 100px 100px 0px 0px;
    background: var(--Dark-primary, #152a31);
    box-shadow: 0px -43px 86.1px 0px rgba(0, 0, 0, 0.25);
    height: 100px;
    z-index: 10;
    display: flex;
    margin: auto;
    margin-bottom: 0;
    width: 100%;
    justify-content: space-between;
    padding: 0 10vw;
    gap: 50px;
}

.section_1_homepage .section_1_footer .section_1_footer_div {
    display: flex;
    gap: 10px;
    align-items: center;
}

.section_1_homepage .section_1_footer .section_1_footer_div svg {
    width: 40px;
}

.section_1_homepage .section_1_footer .section_1_footer_div .section_1_footer_title {
    color: var(--White, #fff);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    white-space: nowrap;
}

.section_1_homepage .section_1_footer .section_1_footer_div .section_1_footer_count {
    color: var(--Green-2, #07dd92);
    font-family: Monument Extended;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}

@media (max-width: 768px) {
    .homepage .section_1_homepage {
        display: none;
    }
}