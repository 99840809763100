.detail_card {
	padding: 3px;
	background: linear-gradient(93.36deg, #07dd92 0.26%, #06b9f0 97.87%);
	border-radius: 20px 20px 20px 0px;
	overflow: hidden;
	margin: 0 5rem;
	overflow: visible;
	height: 400px;
	margin-bottom: 6rem;
}

.detail_card .card_wrapper {
	display: flex;
	padding: 2rem 3rem;
	background-color: white;
	border-radius: 16px 16px 16px 0px;
	position: relative;
	height: 100%;
}

.detail_card .card_wrapper > div {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.detail_card .detail_icons {
	height: 0.8rem;
}
.detail_card .full_charge_icon {
	height: 1.2rem;
}

.detail_card .detail_group_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 20px;
}

.detail_card .detail_card_details {
	max-width: 30vw;
}

.detail_card .detail_card_details_div {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.detail_card .detail_group_container * {
	font-size: 0.8rem;
}

.detail_card .detail_group {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	align-items: center;
	justify-content: space-between;
}

.detail_card .detail_card_heading {
	font-size: 2rem;
	font-weight: bold;
}

.detail_card .card_wrapper .detail_card_desc {
	margin: 0;
	color: #000;
	font-family: Montserrat;
	line-height: 150%;
	font-size: 0.9rem;
	font-style: normal;
}

.detail_card .card_wrapper:not(.design2) .detail_card_details {
	margin: auto;
	margin-right: 0;
	height: 100%;
}

.detail_card .card_wrapper:not(.design2) .detail_card_heading,
.detail_card .card_wrapper:not(.design2) .detail_card_desc {
	text-align: right;
}

.detail_card .detail_group > div > span {
	font-size: 1.4rem;
	font-weight: 600;
}

.detail_card .card_wrapper .icon {
	position: absolute;
}

.detail_card .card_wrapper.design1 .icon {
	bottom: -20px;
	left: 40px;
	height: 450px;
	transform: rotate(-1.825deg);
	opacity: 0;
	transform: translateX(-50px);
	transition: opacity 2s, transform 2s;
}

.detail_card .card_wrapper.design2 .icon {
	top: -20px;
	right: 50px;
	height: 475px;
	opacity: 0;
	transform: translateX(50px);
	transition: opacity 2s, transform 2s;
}

.detail_card .card_wrapper.design3 .icon {
	bottom: -25px;
	left: 0px;
	height: 410px;
	opacity: 0;
	transform: translateX(-50px);
	transition: opacity 2s, transform 2s;
}
.detail_card .threeWheeler4 {
	width: 650px;
	height: auto !important;
}
.detail_card .card_wrapper .threeWheeler5 {
	width: 650px;
	height: auto !important;
	bottom: -90px !important;
	left: -90px !important;
}
.detail_card .card_wrapper .threeWheeler6 {
	width: 650px;
	height: auto !important;
	right: -8rem !important;
	top: 3rem !important;
}
.detail_card .twoWheeler4 {
	right: -10rem !important;
}
.detail_card .card_wrapper .twoWheeler5 {
	height: 550px !important;
	bottom: -40px !important;
}
.detail_card .card_wrapper .fourWheeler1 {
	height: 550px !important;
	top: -110px !important;
	right: -170px !important;
}

@media (max-width: 1025px) {
	.detail_card .card_wrapper.design1 .icon {
		bottom: -20px;
		left: 10px;
	}
	.detail_card .card_wrapper .detail_card_desc {
		font-size: 0.8rem;
	}
}
@media (max-width: 768px) {
	.detail_card .card_wrapper .icon {
		width: 300px !important;
		height: auto !important;
	}
	.detail_card .card_wrapper .threeWheeler4 {
		width: 450px !important;
		height: auto !important;
		left: -6rem !important;
		bottom: 2rem !important;
	}

	.detail_card .card_wrapper .twoWheeler4 {
		width: 550px !important;
		right: -8rem !important;
	}
	.detail_card .card_wrapper .twoWheeler5 {
		width: 550px !important;
		left: -5rem !important;
		bottom: 0 !important;
	}
	.detail_card .card_wrapper .threeWheeler5 {
		width: 450px !important;
		height: auto !important;
		bottom: 2rem !important;
		left: -90px !important;
	}
	.detail_card .card_wrapper .threeWheeler6 {
		width: 500px !important;
		height: auto !important;
		right: -8rem !important;
		top: 6rem !important;
	}
	.detail_card .card_wrapper .fourWheeler1 {
		height: 280px !important;
		width: auto !important;
		top: 50px !important;
		right: -100px !important;
	}
	.detail_card .card_wrapper .fourWheeler2 {
		height: 260px !important;
		width: auto !important;
		top: 25px !important;
		left: -50px !important;
	}
	.detail_card .card_wrapper.design1 .icon {
		bottom: 5rem;
		left: 20px;
		transform: rotate(0deg);
	}
	.detail_card .card_wrapper.design2 .icon {
		top: 3rem;
		right: 40px;
		height: 475px;
	}
	.detail_card .card_wrapper.design3 .icon {
		bottom: 5rem;
		left: 0px;
		height: 410px;
	}
	.detail_card .card_wrapper .detail_card_desc {
		padding-bottom: 1rem;
	}
	.detail_card {
		height: max-content !important;
	}
	.detail_card .card_wrapper.design1 .icon {
		opacity: 0;
		transform: translateX(-50px);
		transition: opacity 2s, transform 2s;
	}
	.vehicles_page .hero_section .background_div {
		background-size: 90% 60%;
	}
	.detail_card .card_wrapper .twoWheeler2 {
		width: 600px !important;
		left: -150px !important;
		bottom: 1rem !important;
	}
	.detail_card .card_wrapper .eka_mobility {
		top: 7rem !important;
	}
}
@media (max-width: 425px) {
	.detail_card {
		padding: 3px;
		border-radius: 10px;

		margin: 5%;
		margin-bottom: 6rem;
	}
	.detail_card .card_wrapper {
		display: flex;
		padding: 2rem 1rem;
		border-radius: 8px;
		width: 100%;
	}
	.detail_card .card_wrapper:not(.design2) .detail_card_details {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 0;
		width: 100%;
	}
	.detail_card .detail_card_details {
		max-width: 100%;
	}
	.detail_card .card_wrapper:not(.design2) .detail_card_heading {
		font-weight: 500;
	}
	.detail_card .card_wrapper:not(.design2) .detail_card_heading,
	.detail_card .card_wrapper:not(.design2) .detail_card_desc {
		text-align: center;
		width: 100%;
	}
	.detail_card .detail_card_heading,
	.detail_card .detail_card_desc {
		text-align: center;
		width: 100%;
	}
	.detail_card {
		height: 500px;
	}
	.detail_card .card_wrapper .icon {
		height: 250px !important;
		width: auto !important;
	}
	.detail_card .card_wrapper .detail_card_desc {
		width: 100%;
	}
	.detail_card .detail_card_details_div {
		align-items: center;
		margin-bottom: 15rem;
	}
	.detail_card .detail_group {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		align-items: center;
		justify-content: center;
	}
	.detail_card .detail_group > div {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;
	}
	.detail_card .detail_group_container * {
		font-size: 0.6rem;
	}
	.detail_card .detail_group > div > span {
		font-size: 1rem;
	}
	.detail_card .detail_group_container {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
	.vehicles_page .hero_section {
		height: 60vh;
	}
	.detail_card .card_wrapper.design1 .icon {
		left: 50px;
	}
	.detail_card .card_wrapper.design1 .icon {
		opacity: 0;
		transform: translateX(-50px);
		transition: opacity 2s, transform 2s;
	}
	.detail_card .card_wrapper.design2 .icon {
		top: 12rem;
		right: 2rem;
		opacity: 0;
		transform: translateX(50px);
		transition: opacity 2s, transform 2s;
	}
	.detail_card .card_wrapper.design3 .icon {
		left: 2rem;
		opacity: 0;
		transform: translateX(-50px);
		transition: opacity 2s, transform 2s;
	}
	.detail_card .card_wrapper .twoWheeler1 {
		top: 16rem !important;
	}
	.detail_card .card_wrapper .twoWheeler2 {
		left: -55px !important;
		bottom: 6rem !important;
	}
	.detail_card .card_wrapper .twoWheeler3 {
		right: 3rem !important;
	}
	.detail_card .card_wrapper .threeWheeler4 {
		width: 350px !important;
		height: auto !important;
		left: 1.5rem !important;
		bottom: 5rem !important;
	}
	.detail_card .card_wrapper .twoWheeler4 {
		right: -3rem !important;
	}

	.detail_card .card_wrapper .twoWheeler5 {
		width: 450px !important;
		height: auto !important;
		left: 1rem !important;
		bottom: 5rem !important;
	}
	.detail_card .card_wrapper .twoWheeler6 {
		left: 3rem !important;
	}
	.detail_card .card_wrapper .threeWheeler5 {
		width: 400px !important;
		height: auto !important;
		bottom: 3rem !important;
		left: -20px !important;
	}
	.detail_card .card_wrapper .threeWheeler6 {
		width: 400px !important;
		height: auto !important;
		right: -1rem !important;
		top: 14rem !important;
	}
	.detail_card .card_wrapper .threeWheeler7 {
		top: 14rem !important;
	}
	.detail_card .card_wrapper .fourWheeler1 {
		top: 140px !important;
		right: -20px !important;
	}
	.detail_card .card_wrapper .fourWheeler2 {
		top: 125px !important;
		left: 10px !important;
	}
	.detail_card .card_wrapper .vehicle7 {
		top: 10rem !important;
		left: 4rem !important;
	}
	.detail_card .card_wrapper .vehicle8 {
		top: 9.5rem !important;
	}
	.detail_card .card_wrapper .eka_mobility {
		top: 10rem !important;
		right: 0rem !important;
	}
	.detail_card .card_wrapper .ev_age {
		left: -25px !important;
	}
	.detail_card .card_wrapper .osm_m1 {
		left: 0px !important;
	}
}
.detail_card .card_wrapper .animate_icons {
	opacity: 1 !important;
	transform: translateX(0) !important ;
}
