.what_we_offer_homepage {
	width: 80vw;
	min-height: 180px;
	flex-shrink: 0;
	border-radius: 25px;
	background: var(--White, #fff);
	box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.25);
	display: flex;
	margin: auto;
	justify-content: space-between;
	position: relative;
	transition: 1s;
}

.what_we_offer_homepage.pos_left {
	margin-left: 0;
	left: -50vw;
	opacity: 0;
}

.what_we_offer_homepage.pos_right {
	margin-right: 0;
	right: -50vw;
	opacity: 0;
}

.what_we_offer_homepage.pos_left.in-view {
	left: 0;
	opacity: 1;
}

.what_we_offer_homepage.pos_right.in-view {
	right: 0;
	opacity: 1;
}

.what_we_offer_homepage .what_we_offer_homepage_img {
	position: relative;
	min-width: calc(80vw * 30 / 100);
	max-width: calc(80vw * 30 / 100);
	align-items: center;
	display: none;
}

.what_we_offer_homepage .what_we_offer_homepage_img.no_absolute {
	overflow: hidden;
}

.what_we_offer_homepage.pos_left .what_we_offer_homepage_img.pos_left {
	display: block;
	border-radius: 25px 0 0 25px;
}

.what_we_offer_homepage.pos_right .what_we_offer_homepage_img.pos_right {
	display: block;
	border-radius: 0 25px 25px 0;
}

.what_we_offer_homepage .what_we_offer_homepage_img.grey {
	background: var(--Grey-3, #f0f4f5);
	box-shadow: -4px 4px 60px 0px rgba(0, 0, 0, 0.12) inset;
}

.what_we_offer_homepage .what_we_offer_homepage_img.green {
	background: var(--Green-3, #b5f5df);
	box-shadow: -4px 4px 60px 0px rgba(0, 0, 0, 0.12) inset;
}

.what_we_offer_homepage .what_we_offer_homepage_img.blue {
	background: var(--Blue-3, #b5eafb);
	box-shadow: -4px 4px 60px 0px rgba(0, 0, 0, 0.12) inset;
}

.what_we_offer_homepage .what_we_offer_homepage_img img {
	position: absolute;
	top: -50px;
	height: 230px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
}

.what_we_offer_homepage .what_we_offer_homepage_img.no_absolute img {
	position: relative;
	top: 0;
	height: 100%;
	width: 100%;
}

.what_we_offer_homepage .what_we_offer_homepage_title_div {
	padding: 30px 100px 30px 60px;
}

.what_we_offer_homepage .what_we_offer_homepage_title_div p {
	margin: 0;
}

.what_we_offer_homepage
	.what_we_offer_homepage_title_div
	.what_we_offer_homepage_title {
	color: #000;
	font-family: Montserrat;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%;
}

.what_we_offer_homepage
	.what_we_offer_homepage_title_div
	.what_we_offer_homepage_desc {
	color: #000;
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	padding-top: 10px;
}

@media (max-width: 768px) {
	.what_we_offer_homepage .what_we_offer_homepage_img img {
		position: static;
		width: 175px;
		height: auto;
	}

	.what_we_offer_homepage.pos_right .what_we_offer_homepage_img.pos_right {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.what_we_offer_homepage.pos_left .what_we_offer_homepage_img.pos_left {
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}
}

@media (max-width: 425px) {
	.homepage .section_4 .what_we_offers {
		padding: 0;
		padding-top: 4rem;
		width: 100%;
	}

	.what_we_offer_homepage.pos_left,
	.what_we_offer_homepage.pos_right {
		margin: auto;
	}

	.what_we_offer_homepage.pos_left .what_we_offer_homepage_img.pos_left {
		display: block;
		min-width: 100%;
		height: 200px;
	}

	.what_we_offer_homepage .what_we_offer_homepage_img.green {
		display: block;
		min-width: 100%;
		height: 200px;
	}

	.what_we_offer_homepage {
		width: 90vw;
	}

	.what_we_offer_homepage .what_we_offer_homepage_img img {
		position: absolute;
		top: -50px;
		height: 250px !important;
		width: auto;
	}

	.what_we_offer_homepage .what_we_offer_homepage_title_div {
		padding: 30px 10px 30px 20px;
	}

	.what_we_offer_homepage.pos_right .what_we_offer_homepage_img.pos_right {
		display: none;
	}

	.what_we_offer_homepage .what_we_offer_homepage_img {
		border-radius: 25px 25px 0 0 !important;
	}
}
