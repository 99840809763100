.header {
	height: 56px;
	width: 100vw;
	background-color: #ffffff;
	box-shadow: -4px 4px 16px #00000040;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 4rem;
	z-index: 11;
	position: relative;
}

.header .pc {
	display: block;
}

.header .mobile {
	display: none;
}

.header .logo_a {
	display: flex;
}

.header .logo {
	margin: auto 0;
	height: 40px;
	cursor: pointer;
}

.header .group {
	height: 100%;
	display: flex;
	align-items: center;
	display: flex;
	gap: 2rem;
}

.header .nav-links {
	align-items: flex-start;
	display: inline-flex;
	gap: 50px;
	justify-content: center;
}

.header .nav-link {
	color: var(--black, #000);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	cursor: pointer;
	transition: 0.1s;
}

.header .nav-link:hover {
	color: #00c67a;
}

.header .flag {
	width: 25px;
}

.header .div {
	color: var(--black, #000);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.header .arrow-down {
	width: 10px;
	height: 5px;
}

.header.design_2 {
	height: 60px;
	box-shadow: none;
	background-color: white;
	justify-content: flex-start;
	gap: 5rem;
}

.header.design_2 .logo {
	margin: auto 0 0;
}

.header.design_2 .group {
	height: fit-content;
	margin: auto 0 0;
}

.header .mobile {
	align-items: center;
}

.header .mobile svg {
	cursor: pointer;
}

.dropdown {
	width: fit-content;
	background: white;
	position: absolute;
	top: 40px;
	right: 0;
	display: flex;
	flex-direction: column;
	border-radius: 0 0 0 15px;
	opacity: 0;
	visibility: hidden;
	transform-origin: top;
	transform: scaleY(0);
	transition: opacity 0.5s, visibility 0s linear 0.5s;
	z-index: 999;
	box-shadow: 0px 8px 9px 0px #00000040;
}

.header .dropdown.opened {
	opacity: 1;
	visibility: visible;
	transition: opacity 0.5s, visibility 0s linear 0s;
	animation: slideDown 0.5s forwards;
}

.floating_div {
	width: 100%;
	height: 3rem;
	background-color: #000;
	box-shadow: -2px 2px 10px 0px rgba(255, 255, 255, 0.2);
	z-index: 10;
	cursor: pointer;
	transition: 0.5s;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	padding: 10px 0;
	gap: 5vw;
	justify-content: space-between;
	padding-left: 2rem;
}

.floating_div .hidden_content {
	display: flex;
	flex-direction: row;
	gap: 2vw;
	margin-right: 4vw;
}

.floating_div .hidden_content p {
	text-align: center;
	margin: 0;
}

.floating_div .hidden_content p span,
.floating_div .hidden_content p time {
	color: #ff505c;
	text-align: center;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 140%;
	text-transform: uppercase;
}

.floating_div .hidden_content p span.last {
	color: #ff505c;
	font-family: Montserrat;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 140%;
	text-transform: uppercase;
	margin-left: 1rem;
}

.floating_div .floating_div_title {
	display: flex;
	flex-direction: row;
	gap: 2rem;
	align-items: center;
	justify-content: center;
}

.floating_div p {
	text-align: center;
	margin: 0;
	color: #ff505c;
	text-align: center;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 140%;
	display: flex;
	align-items: center;
}

@keyframes slideDown {
	from {
		transform: scaleY(0);
	}

	to {
		transform: scaleY(1);
	}
}

.header .dropdown.closing {
	opacity: 0;
	transition: opacity 0.5s, visibility 0s linear 0.5s;
	animation: slideUp 0.5s forwards;
}

@keyframes slideUp {
	from {
		transform: scaleY(1);
	}

	to {
		transform: scaleY(0);
	}
}

.header .dropdown .cross {
	align-self: flex-end;
	width: 9px;
	margin-right: 30px;
	margin-top: 20px;
	cursor: pointer;
}

.header .dropdown .cross path {
	fill: black;
}

.header .dropdown .navbar_as {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-top: 30px;
	padding-bottom: 40px;
	width: 200px;
}

.header .dropdown .navbar_as .navbar_a {
	color: var(--White, #000);
	text-align: right;
	font-family: Montserrat;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	border-bottom: 1px solid #595959;
	padding-right: 30px;
	padding-bottom: 10px;
	cursor: pointer;
}

@media only screen and (max-width: 880px) {

	.header,
	.header.design_2 {
		height: 40px;
		padding: 0 20px;
		box-shadow: -4px 4px 16px #00000040;
	}

	.header .logo {
		height: 25px;
		margin: auto !important;
	}

	.header .group {
		display: none;
	}

	.header .pc {
		display: none;
	}

	.header .mobile {
		display: flex;
	}

	.header.design_2 {
		justify-content: space-between;
	}

	.floating_div .time_left_para {
		display: none;
	}
}

@media (max-width: 425px) {
	.floating_div {
		gap: 2vw;
	}

	.floating_div .hidden_content {
		gap: 3vw;
		margin-right: 4vw;
	}

	.floating_div .floating_div_title svg {
		width: 17px;
	}

	.floating_div .hidden_content p span,
	.floating_div .hidden_content p time {
		color: #ff505c;
		font-size: 15px;
	}

	.floating_div .hidden_content p span.last {
		font-size: 10px;
		margin-left: 1rem;
	}

	.floating_div .floating_div_title {
		flex-direction: row;
		gap: 1rem;
	}

	.floating_div p {
		font-size: 10px;
	}

	.floating_div .hidden_content p span,
	.floating_div .hidden_content p time {
		font-size: 10px;
	}
}