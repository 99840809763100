* {
	box-sizing: border-box;
}

html {
	overflow-x: hidden;
}

head,
body {
	margin: 0;
	font-family: "Montserrat", sans-serif !important;
}

@font-face {
	font-family: "Monument Extended";
	src: url("fonts/MonumentExtended-Regular.otf") format("opentype");
	font-weight: normal;
	font-display: swap
}

@font-face {
	font-family: "Monument Extended";
	src: url("fonts/MonumentExtended-Ultrabold.otf") format("opentype");
	font-weight: bold;
	font-display: swap
}

@font-face {
	font-family: "Montserrat";
	src: url("fonts/Montserrat-Regular.ttf") format("truetype");
	font-weight: bold;
	font-display: swap
}

a {
	text-decoration: none;
}