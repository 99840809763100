.featured_article_swiper_div {
	display: flex;
	flex-direction: column;
	height: 100%;
	cursor: pointer;
	background-color: white;
}

.featured_article_swiper_div .featured_article_img {
	width: 100%;
}

.featured_article_swiper_div .featured_article_img img {
	width: 100%;
}

.featured_article_swiper_div .featured_article_date {
	color: var(--Grey-1, #8399a3);
	font-family: Montserrat;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	padding-top: 10px;
}

.featured_article_swiper_div .featured_article_desc {
	color: var(--black, #000);
	font-family: Montserrat;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	padding-top: 20px;
	flex-grow: 1;
	transition: 0.05s;
}

.featured_article_swiper_div:hover .featured_article_desc {
	color: var(--Blue-2, #06b9f0);
	font-weight: 600;
}

.featured_article_swiper_div .featured_article_bottom_border {
	height: 6px;
	background: var(--Grey-2, #c2d0d6);
	border-radius: 5px;
	transition: 0.05s;
}

.featured_article_swiper_div:hover .featured_article_bottom_border {
	background: var(--Blue-2, #06b9f0);
}

@media (max-width: 768px) {
	.featured_article_swiper_div .featured_article_desc {
		color: var(--Blue-2, #06b9f0);
		font-weight: 600;
	}
}

@media (max-width: 426px) {
	.homepage .section_7 .featured_article_swiper .swiper-slide {
		width: 100% !important;
	}

	.homepage .section_7 .featured_article_swiper .swiper-wrapper {
		width: 100% !important;
	}
}
