.auto_carousel {
	width: auto;
}

.slick-dots li button {
	width: 60px !important;
	height: 60px !important;
	background-color: #fff !important;
	border: 2px solid #c2d0d6 !important;
	border-radius: 4rem !important;
	color: #c2d0d6 !important;
	font-family: Monument Extended !important;
	font-size: 2rem !important;
	font-weight: 400 !important;
	line-height: 100% !important;
	opacity: 100% !important;
}

.slick-dots .slick-active {
	height: max-content !important;
	width: max-content !important;
	margin: auto !important;
}

.slick-dots li {
	height: max-content !important;
	width: max-content !important;
	margin: auto !important;
}

.slick-dots .slick-active button {
	width: 80px !important;
	height: 80px !important;
	background: linear-gradient(46deg,
			#0092bd 0.24%,
			#00c67a 97.74%) !important;
	color: #f0f4f5 !important;
	text-align: center !important;
	font-family: Monument Extended !important;
	font-size: 48px !important;
	font-weight: 400 !important;
	line-height: 100% !important;
	opacity: 100% !important;
	-webkit-box-shadow: 0px 0px 5px 20px rgba(3, 171, 112, 0.2);
	-moz-box-shadow: 0px 0px 5px 20px rgba(3, 171, 112, 0.2);
	box-shadow: 0px 0px 5px 20px rgba(3, 171, 112, 0.2);
	margin: auto !important;
	margin-bottom: 1rem !important;
}

.slick-dots {
	width: 100%;
	height: 5rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between !important;
	position: absolute;
	top: -6rem;
	left: 0;
	background: url(../../assets/images/driverPartner/DottedLine.webp);
	background-position: center;
	background-size: 25rem 4px;
	background-repeat: no-repeat;
}

@media (max-width: 1400px) {
	.slick-dots .slick-active button {
		width: 70px !important;
		height: 70px !important;
		font-size: 40px !important;

		box-shadow: 0px 0px 5px 15px rgba(3, 171, 112, 0.2);
		margin: auto !important;
		margin-bottom: 1rem !important;
	}

	.slick-dots li button {
		width: 50px !important;
		height: 50px !important;
		border-radius: 4rem !important;
		font-size: 1.25rem !important;
	}
}

@media (max-width: 1100px) {
	.auto_carousel h3 {
		font-size: 1.1rem !important;
	}

	.auto_carousel p {
		font-size: 0.8rem !important;
		width: 65% !important;
	}

	.slick-dots {
		height: 4rem;
		top: -4rem;
		background-size: 25rem 4px;
	}
}

@media (max-width: 768px) {
	.auto_carousel h3 {
		font-size: 0.9rem !important;
		margin-top: 1rem !important;
	}

	.auto_carousel p {
		font-size: 0.7rem !important;
		width: 90% !important;
	}

	.auto_carousel {
		width: 100% !important;
	}

	.slick-dots .slick-active button {
		width: 60px !important;
		height: 60px !important;
		font-size: 1.5rem !important;
		box-shadow: 0px 0px 5px 10px rgba(3, 171, 112, 0.2) !important;
	}

	.slick-dots li button {
		width: 35px !important;
		height: 35px !important;
		font-size: 0.6rem !important;
	}

	.slick-dots {
		height: 4rem;
		top: -4rem;
		background-size: 15rem 4px;
	}
}

@media (max-width: 425px) {
	.auto_carousel h3 {
		font-size: 1.1rem !important;
	}

	.auto_carousel p {
		font-size: 0.9rem !important;
	}

	.auto_carousel .slick-dots {
		height: 4rem;
		top: -6rem;
		background-size: 15rem 4px;
	}
}