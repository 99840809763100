footer {
	background-color: rgba(21, 42, 49, 1);
	padding: 4rem 0;
	padding-top: 8rem;
	padding-bottom: 0;
	display: flex;
	flex-direction: row;
	min-height: 60vh;
	font-family: Montserrat;
	position: relative;
	z-index: 9;
}

footer * {
	font-size: 1rem;
	color: white;
}

footer .contact-container {
	width: 40%;
	background: linear-gradient(180deg, #152a31 0%, #000000 102.48%);
	padding-top: 2rem;
	padding-left: 4rem;
}

footer .mail-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
}

footer .mail-icon {
	height: 1.5rem;
}

footer .contact-type-container {
	margin-top: 2rem;
}

footer .contact-type {
	width: 12rem;
	padding: 1rem 1.5rem;
	border: 1px solid #8399a3;
	background: #152a31;
	border-radius: 2rem;
	box-shadow: -4px 4px 16px 0px rgba(0, 0, 0, 0.1);
	color: white;
	margin-bottom: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: 500ms;
}

footer .contact-type:hover {
	background-color: white;
	color: black;
}

footer .contact-type:hover span {
	color: black;
}

footer .contact-type span {
	font-size: 1.5rem;
}

footer .gradient-text {
	background: linear-gradient(131.65deg, #07dd92 24.38%, #06b9f0 75.69%);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	font-family: Monument Extended;
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 160%;
}

footer .link-container {
	margin-left: 2rem;
}

footer .footer-links-group {
	display: flex;
	flex-direction: row;
	gap: 2rem;
	margin-bottom: 3rem;
}

footer .footer-links {
	padding: 0.5rem;
	border-bottom: 3px solid white;
	transition: 500ms;
}
@media (max-width: 520px) {
	footer .footer-links {
		padding: 0.25rem;
	}
}
footer .footer-links:hover {
	border-bottom: 3px solid rgba(7, 221, 146, 1);
}

footer .footer-active-link {
	border-bottom: 3px solid rgba(7, 221, 146, 1);
}

footer .footer-p-cantainer {
	display: flex;
}

footer .footer-p-cantainer > p {
	margin-right: 4rem;
	font-weight: 500;
	line-height: 38px;
}

footer .mail-container p {
	color: #fff;
	font-family: Montserrat;
	font-size: 1rem;
	font-weight: 500;
	line-height: 160%;
	/* 38.4px */
}

footer .top_shape {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 5rem;
	background-color: white;
	border-radius: 0px 0px 150px 150px;
}

footer .footer_company_info > :first-child {
	display: flex;
	gap: 6rem;
	margin-bottom: 2rem;
}

footer .footer_company_info .details_container {
	width: max-content;
}

footer .footer_company_info .details_container > .Details_underlines {
	width: 0px;
	height: 3px;
	transition: 0.5s;
}

footer .footer_company_info .details_container:hover > .Details_underlines {
	width: 100%;
	background-color: rgba(7, 221, 146, 1);
}

footer .footer_company_info span {
	color: #c2d0d6;
	font-size: 1rem;
	font-weight: 400;
	line-height: 160%;
	margin: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
}

footer .footer_company_info p {
	margin: 0.8rem 0;
	width: max-content;
	cursor: pointer;
	margin-bottom: 5px;
}

footer .link-container {
	margin: auto 2rem 2rem;
}

footer .footer_company_info .location_p:hover {
	cursor: text;
}

footer .startup_india {
	height: 40px;
	margin-top: 20px;
}

footer .handle_image {
	margin: 0.5rem;
	margin-top: 2rem;
}

footer .copyright_para {
	margin-top: 2rem;
	color: rgb(187, 187, 187);
}

@media (max-width: 1100px) {
	footer .link-container {
		margin: auto 4rem;
	}
}

@media (max-width: 768px) {
	footer {
		padding: 0 0 0 !important;
		padding-top: 4rem;
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		min-height: auto;
		font-family: Montserrat;
		position: relative;
	}

	footer .top_shape {
		display: none;
	}

	footer .link-container {
		margin: 4rem 0;
		margin-bottom: 2rem;
		width: 100%;
		padding: 1rem 1.5rem;
	}

	footer .footer_company_info > :first-child {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		margin-bottom: 2rem;
	}

	footer .footer-links-group {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 0.5rem;
		margin-bottom: 3rem;
	}

	footer .contact-container {
		width: 100%;
		background: linear-gradient(180deg, #152a31 0%, #000000 102.48%);
		padding: 2rem calc(1.5rem + 8px);
	}

	footer .gradient-text {
		font-size: 1.2rem;
	}

	footer .mail-container {
		gap: 0.2rem;
	}

	footer .mail-container p {
		color: #fff;
		font-family: Montserrat;
		font-size: 0.8rem;
	}

	footer .contact-type-container {
		margin-top: 2rem;
	}

	footer .contact-type {
		width: 12rem;
		padding: 0.6rem 1.5rem;
		color: white;
		margin-bottom: 1rem;
	}

	footer * {
		font-size: 0.8rem;
	}

	footer .footer_company_info span {
		font-size: 0.8rem;
	}
}

@media (max-width: 425px) {
	footer .footer_company_info p {
		color: rgba(7, 221, 146, 1);
	}

	footer .footer_company_info .location_p {
		width: 100%;
		color: white;
	}

	footer .footer_company_info .details_container:hover > .Details_underlines {
		width: 0%;
		background-color: none;
	}

	footer .footer_company_info {
		padding: 0.5rem;
		padding-bottom: 0;
	}

	footer .contact-type:hover {
		background-color: #152a31;
		color: white;
	}

	footer .contact-type:hover span {
		color: white;
	}
}
