.driver_partner_expandable_divs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.driver_partner_expandable_divs .expandable_div {
  border-radius: 25px;
  width: 4rem;
  height: 100%;
  color: #b5eafb;
  text-align: center;
  font-family: Monument Extended;
  font-size: 1rem;
  font-weight: 400;
  line-height: 160%;
  cursor: pointer;
  transition: 600ms;
  overflow: hidden;
}

.driver_partner_expandable_divs .expandable_div div {
  width: 100%;
  height: 100%;
  background: linear-gradient(46deg,
      rgba(0, 146, 189, 0.4) 0.24%,
      rgba(0, 198, 122, 0.4) 97.74%);
  padding: 1rem 0;
}

.driver_partner_expandable_divs .expandable_div .expanded_div {
  display: none;
}

.driver_partner_expandable_divs .active_expandable_div {
  width: 100% !important;
  max-width: 23rem;
  border-radius: 25px;
  border: 10px solid rgba(6, 185, 240, 0.2);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.driver_partner_expandable_divs .active_expandable_div div {
  background: linear-gradient(46deg,
      #0092bd 0.24%,
      #00c67a 97.74%) !important;
}

.driver_partner_expandable_divs .active_expandable_div .expanded_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 160%;
  color: white;
  opacity: 1;
  animation: fadeIn 2s;
}

.driver_partner_expandable_divs .active_expandable_div span {
  display: none;
}

.driver_partner_expandable_divs .expandable_div .expanded_div p {
  margin: auto 2rem;
}

@media (max-width: 1400px) {
  .driver_partner_expandable_divs {
    gap: 1rem;
  }

  .driver_partner_expandable_divs .expandable_div {
    min-width: 3rem;
    font-size: 1rem;
  }

  .driver_partner_expandable_divs .active_expandable_div {
    border-radius: 25px;
    border: 10px solid rgba(6, 185, 240, 0.2);
  }

  .driver_partner_expandable_divs .expandable_div .expanded_div {
    font-size: 1rem;
  }

  .driver_partner_expandable_divs .expanded_div>img {
    width: auto;
    height: 60px;
  }
}

@media (max-width: 769px) {

  .driver_partner_expandable_divs .expandable_div .expanded_div {
    font-size: 0.8rem;
  }

  .driver_partner_expandable_divs .expandable_div {
    width: 3rem;
    font-size: 0.8rem;
  }

  .driver_partner_expandable_divs .active_expandable_div {
    border: 6px solid rgba(6, 185, 240, 0.2);
  }
}

@media (max-width: 425px) {
  .driver_partner_expandable_divs {
    width: 100%;
    gap: 1rem;
    flex-direction: column;
  }

  .driver_partner_expandable_divs .active_expandable_div {
    border-radius: 25px;
    height: 25rem;
    border: 5px solid rgba(6, 185, 240, 0.2);
  }

  .driver_partner_expandable_divs .active_expandable_div .expanded_div {
    padding: 4rem 0.5rem;
  }

  .driver_partner_expandable_divs .expandable_div {
    border-radius: 15px;
    width: 100%;
    color: #b5eafb;
    text-align: center;
    font-family: Monument Extended;
    font-size: 1rem;
  }

  .driver_partner_expandable_divs .expandable_div span {
    width: 100%;
    text-align: left;
    padding: 1rem;
  }

  .driver_partner_expandable_divs .expandable_div .expanded_div p {
    padding: 0 0.5rem;
    font-size: 0.9rem;
  }

  .driver_partner_expandable_divs .expanded_div>img {
    width: auto;
    height: 70px;
  }
}