@media (max-width: 425px) {
	.hybrid_card .Eicher_Pro {
		height: 505px !important;
		left: 0px !important;
	}
	.hybrid_card .Volvo_Eicher {
		height: 505px !important;
		top: 13rem !important;
		right: 1rem !important;
	}

	.hybrid_card .RHINO {
		height: 505px !important;
		top: 10rem !important;
		right: -2rem !important;
	}
}
