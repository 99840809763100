.brands_card {
	border-radius: 1rem;
	box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.25);
	padding: 0;
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	margin: 2rem auto;
	margin-bottom: 4rem;
	width: min-content;
	height: 110px;
	overflow: hidden;
}

.brands_card .brand_img {
	margin: auto 2.5rem;
	height: 50px;
	width: auto;
}

@media (min-width: 1200px) {
	.brands_card {
		border-radius: 1rem;
		width: 90%;
		gap: 0;
		justify-content: space-around;
	}
}
@media (max-width: 1200px) {
	.brands_card {
		width: 90%;
	}
}
@media (max-width: 768px) {
	.brands_card {
		display: none;
	}
}
