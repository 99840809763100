.profile_card {
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	overflow: hidden;
}
.profile_card img {
	background: linear-gradient(91deg, #d3d9dc 0.57%, #fff 99.56%);
}
.profile_card h4 {
	color: white;
	font-family: Montserrat;
	font-size: 0.8rem;
	font-style: normal;
	font-weight: 600;
	line-height: 120%;
	text-transform: uppercase;
	margin: 0;
}
.profile_card p {
	color: #b5f5df;
	font-family: Montserrat;
	font-size: 0.6rem;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	text-transform: uppercase;
}
.profile_card > div {
	padding: 1rem 0;
	text-align: center;
	background: #0d3444;
}
@media (min-width: 767px) {
	.profile_card h4 {
		font-size: 0.6rem;
	}
	.profile_card p {
		font-size: 0.4rem;
	}
	.profile_card > img {
		width: 150px;
		height: auto;
	}
	.profile_card > div {
		padding: 0.5rem 0;
	}
}
@media (min-width: 1200px) {
	.profile_card > img {
		width: 250px !important;
		height: auto;
	}
	.profile_card h4 {
		font-size: 0.6rem;
	}
	.profile_card h4 {
		font-size: 0.9rem;
		margin-top: 0.5rem;
	}
	.profile_card p {
		font-size: 0.7rem;
	}
}
