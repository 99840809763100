.why_choose_us_chart_container .slice {
  cursor: pointer;
}

.why_choose_us_chart_container .slice path {
  transition: fill 1s ease;
}

.why_choose_us_chart_container .slice textPath {
  fill: var(--Grey-1, #8399A3);
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
}

.why_choose_us_chart_container .slice .on_hover {
  display: none;
}

.why_choose_us_chart_container .slice.selected textPath {
  display: none;
}

.why_choose_us_chart_container .slice.selected .stroke {
  fill: url(#gradientHovered);
}

.why_choose_us_chart_container .slice.selected .on_hover {
  display: block;
}