.case_study_homepage {
	padding: 1px;
	border-radius: 5px;
	border-top: 7px solid rgba(194, 208, 214, 0.6);
	position: relative;
	z-index: 1;
	transition: 0.2s;
	overflow: hidden;
}

.case_study_homepage:hover {
	border-radius: 25px;
}

.case_study_homepage::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: scaleY(0);
	transform-origin: top center;
	background: rgba(194, 208, 214, 0.6);
	z-index: -1;
	transition: transform 0.3s;
	border-radius: 0 0 25px 25px;
}

.case_study_homepage:hover::after {
	transform: scaleY(1);
}

.case_study_homepage .case_study_body {
	padding: 10px 20px;
}

.case_study_homepage .number {
	color: #000;
	font-family: Monument Extended;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	padding-bottom: 10px;
}

.case_study_homepage .logo {
	width: fit-content;
	height: 70px;
	transition: 0.2s;
}

.case_study_homepage .logo img {
	height: 100%;
	/* width: 100%; */
}

.case_study_homepage .case_study_title {
	color: #000;
	font-family: "Montserrat";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	padding-top: 15px;
	position: relative;
}

.case_study_homepage:hover .case_study_title {
	font-weight: 500;
}

.case_study_homepage .case_study_desc {
	color: #000;
	font-family: Montserrat;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	padding-top: 10px;
	display: block;
	position: relative;

	opacity: 0;
	visibility: hidden;
	max-height: 0;
	overflow: hidden;
	transition: 0.15s ease-in-out;
}

.case_study_homepage:hover .case_study_desc {
	opacity: 1;
	visibility: visible;
	max-height: 100%;
}

.case_study_homepage:hover .logo {
	width: fit-content;
	height: 50px;
}

.case_study_homepage .case_study_footers {
	display: none;
	gap: 1px;
	background: var(--Grey-3, #f0f4f5);
	border-radius: 0 0 25px 25px;
	display: flex;

	opacity: 0;
	visibility: hidden;
	max-height: 0;
	overflow: hidden;
	transition: 0.15s ease-in-out;
}

.case_study_homepage .case_study_footers table {
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
}

.case_study_homepage .case_study_footers table tr:first-child {
	border-bottom: 1px solid #c2d0d6;
}

.case_study_homepage .case_study_footers table td:first-child {
	border-right: 1px solid #c2d0d6;
}

.case_study_homepage:hover .case_study_footers {
	opacity: 1;
	visibility: visible;
	max-height: 100%;
}

.case_study_homepage .case_study_footers .case_study_footer {
	flex-grow: 1;
	margin: auto 0;
	border-right: 1px solid #c2d0d6;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.case_study_homepage .case_study_footers .case_study_footer:last-child {
	border: 0;
}

.case_study_homepage .case_study_footers .case_study_footer p {
	margin: 0;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	max-height: 0;
	overflow: hidden;
	transition: 0.15s ease-in-out;
}

.case_study_homepage:hover .case_study_footers .case_study_footer p {
	opacity: 1;
	visibility: visible;
	max-height: 100%;
}

.case_study_homepage
	.case_study_footers
	.case_study_footer
	.case_study_footer_title {
	color: var(--Grey-1, #8399a3);
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%;
}

.case_study_homepage
	.case_study_footers
	.case_study_footer
	.case_study_footer_desc {
	color: var(--black, #000);
	font-family: Montserrat;
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%;
}

.case_study_homepage .case_study_logo_light {
	width: 150px;
	position: absolute;
	top: 0;
	right: 0;
	display: block;
	opacity: 0;
	visibility: hidden;
	max-height: 0;
	overflow: hidden;
	transition: 0.15s ease-in-out;
}

.case_study_homepage:hover .case_study_logo_light {
	opacity: 1;
	visibility: visible;
	max-height: 100%;
}
@media (max-width: 768px) {
	.case_study_homepage .case_study_logo_light {
		width: 100px;
		margin: auto;
	}
}
