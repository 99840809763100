.vehicles_page {
	position: relative;
	overflow-x: hidden;
}

.vehicles_page .hero_section {
	background: linear-gradient(180deg, #152a31 0%, #000000 102.48%);
	background-repeat: no-repeat;
	border-radius: 30px;
	color: white;
	height: 60vh;
	z-index: 1;
	position: relative;
	margin: 10px 30px 5rem;
}

.vehicles_page .hero_section .background_div {
	background-position: right;
	background-repeat: no-repeat;
	background-size: 45% 86%;
	height: 100%;
	border-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.vehicles_page .hero_section .hero_heading {
	width: max-content;
	margin: 0;
	font-size: 3.5vw;
	font-weight: 400;
	padding-bottom: 0;
	text-transform: uppercase;
	background: linear-gradient(46.21deg, #0092bd 0.24%, #00c67a 97.74%),
		linear-gradient(0deg, #000000, #000000);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	font-family: Monument Extended;
}

.vehicles_page .hero_section .hero_heading_span {
	color: whitesmoke;
}

.vehicles_page .hero_section .image_group {
	position: relative;
	height: 100%;
	width: fit-content;
	display: flex;
	align-items: center;
	margin: auto;
	margin-right: 40px;
}

.vehicles_page .hero_section .image_group_hero_img {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.vehicles_page .hero_section .image_group_hero_img .feature_ele.f1 {
	position: absolute;
	top: 120px;
	left: -200px;
}

.vehicles_page .hero_section .image_group_hero_img .feature_ele.f2 {
	position: absolute;
	top: 190px;
	left: -230px;
}

.vehicles_page .hero_section .image_group_hero_img .feature_ele.f3 {
	position: absolute;
	top: 260px;
	left: -260px;
}

.vehicles_page .hero_section span.hero_img_title {
	color: #fff;
	font-family: Montserrat;
	font-size: 25px;
	font-style: normal;
	font-weight: 500;
}

.vehicles_page .hero_section .hero_img {
	width: 40vw;
}

.vehicles_page .hero_section .feature_ele {
	font-size: 2rem;
	display: flex;
	align-items: center;
	gap: 10px;
	position: relative;
}

.vehicles_page .hero_section .feature_ele svg {
	height: 18px;
}

.vehicles_page .hero_section .feature_ele span.small {
	color: var(--White, #fff);
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
}

.vehicles_page .hero_section .feature_ele span.medium {
	color: var(--White, #fff);
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
}

.vehicles_page .hero_section .feature_ele span.large {
	color: var(--White, #fff);
	font-family: Montserrat;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
}

.vehicles_page .hero_section .white_circle {
	margin: 0.5rem;
}

.vehicles_page .shape1 {
	position: absolute;
	width: 3rem;
	top: 4rem;
	right: 30px;
}

.vehicles_page .shape2 {
	position: absolute;
	width: 15rem;
	height: 15rem;
	top: 30rem;
	left: -11rem;
}

.vehicles_page .shape3 {
	position: absolute;
	width: 10rem;
	bottom: 15rem;
	right: -5rem;
	rotate: -4deg;
	display: none;
}

.vehicles_page .detail_cards {
	padding-top: 150px;
	position: relative;
}
.vehicles_page .vehicle_btn_group {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 1rem;
}
.vehicles_page .vehicle_type_btn {
	border-radius: 15px;
	background-color: #defff4;
	padding: 1rem;
	border: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 1.5rem;
	color: #434343;
	font-family: Montserrat;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 38.4px */
	cursor: pointer;
}
.vehicles_page .vehicle_type_btn svg path {
	fill: black;
}
.vehicles_page .vehicle_type_btn_active {
	border-radius: 15px;
	background: var(
		--S-1,
		linear-gradient(46deg, #0092bd 0.24%, #00c67a 97.74%)
	);
	color: white;
}
.vehicles_page .vehicle_type_btn_active svg path {
	fill: white;
}

@media (max-width: 768px) {
	.vehicles_page .hero_section .hero_heading {
		font-size: 2rem;
		padding: 3rem;
	}

	.vehicles_page .hero_section .feature_ele {
		font-size: 1.5rem;
		gap: 5px;
	}

	.vehicles_page .hero_section .feature_ele span.medium {
		font-size: 0.8rem;
	}

	.vehicles_page .hero_section .feature_ele span.small {
		font-size: 0.6rem;
	}

	.vehicles_page .hero_section .feature_ele span.large {
		font-size: 1rem;
	}

	.vehicles_page .hero_section .image_group_hero_img .feature_ele.f1 {
		top: 120px;
		left: -120px;
	}

	.vehicles_page .hero_section .image_group_hero_img .feature_ele.f2 {
		left: -130px;
	}

	.vehicles_page .hero_section .image_group_hero_img .feature_ele.f3 {
		left: -130px;
	}
	.vehicles_page .vehicle_btn_group {
		margin-top: 8rem;
	}
	.vehicles_page .vehicle_type_btn {
		font-size: 2vw;
		border-radius: 10px;
	}
}

@media (max-width: 425px) {
	.vehicles_page .hero_section .hero_heading {
		font-size: 1rem;
		padding: 2rem;
	}

	.vehicles_page .hero_section {
		border-radius: 20px;
		height: 10rem !important;
		margin: 5%;
	}

	.vehicles_page .hero_section .background_div {
		background-size: 100% 100% !important;
		background-position: top;
	}

	.vehicles_page .hero_section .image_group {
		display: flex;
		flex-direction: column-reverse;
		margin-right: 0;
		height: auto;
		margin-bottom: 2rem;
	}

	.vehicles_page .hero_section .hero_img {
		width: 70vw;
	}

	.vehicles_page .hero_section span.hero_img_title {
		font-size: 0.8rem;
		margin-bottom: 1.5rem;
	}

	.vehicles_page .hero_section .feature_ele svg {
		height: 12px;
	}

	.vehicles_page .hero_section .image_group_hero_img .feature_ele.f1 {
		top: 1rem;
		left: -4.25rem;
		width: 40%;
	}

	.vehicles_page .hero_section .image_group_hero_img .feature_ele.f2 {
		top: 8rem;
		left: -4.25rem;
		width: 40%;
	}

	.vehicles_page .hero_section .image_group_hero_img .feature_ele.f3 {
		top: 5rem;
		left: -4.25rem;
		width: 40%;
	}

	.vehicles_page .hero_section .feature_ele span.large {
		font-size: 1rem;
	}

	.vehicles_page .hero_section .feature_ele span.medium {
		font-size: 0.7rem;
	}

	.vehicles_page .hero_section .feature_ele span.small {
		font-size: 0.6rem;
	}

	.vehicles_page .hero_section .feature_ele {
		font-size: 1rem;
		gap: 0.2rem;
		width: 50%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.vehicles_page .shape1 {
		width: 0.8rem;
		top: 0rem;
		right: 0.25rem;
	}

	.vehicles_page .detail_cards {
		padding-top: 2rem;
	}

	.vehicles_page .shape2 {
		position: absolute;
		width: 4rem;
		height: 4rem;
		top: 35rem;
		left: -2.5rem;
	}

	.vehicles_page .shape3 {
		position: absolute;
		width: 2rem;
		bottom: 33rem;
		right: -1rem;
		rotate: -4deg;
	}
	.vehicles_page .vehicle_type_btn {
		padding: 0.8rem;
		gap: 0.5rem;
		width: 40%;
	}
	.vehicles_page .vehicle_type_btn svg {
		width: 20px;
		height: auto;
	}
	.vehicles_page .vehicle_btn_group {
		margin-top: 4rem;
		flex-wrap: wrap;
	}
}

.vehicles_page .hero_section .hero_heading {
	opacity: 0;
	transform: translateX(-50px);
	transition: opacity 2s, transform 2s;
}
.vehicles_page .hero_section .feature_ele {
	opacity: 0;
	transform: translateX(-50px);
	transition: opacity 2s, transform 2s;
}
.vehicles_page .hero_section span.hero_img_title {
	opacity: 0;
	transform: translateX(100px);
	transition: opacity 2s, transform 2s;
}
.animate_hero_ele {
	opacity: 1 !important;
	transform: translateX(0) !important ;
}
