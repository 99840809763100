.contact_us_page {
	display: flex;
	padding: 50px 70px 30px 100px;
	justify-content: space-between;
}

.contact_us_page .contact_us_page_desc {
	width: 40%;
}

.contact_us_page .contact_us_page_desc p {
	margin: 0;
}

.contact_us_page .contact_us_page_desc .contact_us_page_desc_head {
	color: #000;
	font-family: Montserrat;
	font-size: 45px;
	font-style: normal;
	font-weight: 500;
	margin: 0;
}

.contact_us_page .contact_us_page_desc .contact_us_page_desc_desc {
	color: #000;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	padding-top: 20px;
	margin-bottom: 3rem;
}

.contact_us_page .contact_us_page_desc .contact_us_page_desc_body {
	color: #000;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	border-bottom: 1px solid #000;
	width: fit-content;
	padding-bottom: 3px;
	padding-top: 50px;
}

.contact_us_page .contact_us_page_desc .contact_us_page_desc_contact_div {
	display: flex;
	align-items: center;
	gap: 30px;
	padding-top: 20px;
}

.contact_us_page
	.contact_us_page_desc
	.contact_us_page_desc_contact_div
	.contact_us_page_desc_contact_icon
	img {
	width: 20px;
}

.contact_us_page
	.contact_us_page_desc
	.contact_us_page_desc_contact_div
	.contact_us_page_desc_contact_info
	p:first-child {
	padding: 0;
}

.contact_us_page
	.contact_us_page_desc
	.contact_us_page_desc_contact_div
	.contact_us_page_desc_contact_info
	p {
	color: #000;
	font-family: Montserrat;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	padding-top: 15px;
}

.contact_us_page
	.contact_us_page_form
	.contact_us_page_form_tabs
	.ant-tabs-tab-btn {
	color: #000;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
}

.contact_us_page
	.contact_us_page_form
	.contact_us_page_form_tabs
	.ant-tabs-nav::before {
	border: 0;
}

.contact_us_page
	.contact_us_page_form
	.contact_us_page_form_tabs
	.ant-tabs-ink-bar {
	background: linear-gradient(180deg, #0092bd 0%, #00c67a 100%);
	height: 3px;
	border-radius: 5px;
}

.contact_us_page .contact_us_page_form .contact_us_page_form_div .space {
	height: 20px;
}

.contact_us_page .contact_us_page_form .contact_us_page_form_div p {
	margin: 0;
}

.contact_us_page .contact_us_page_form .contact_us_page_form_div {
	border-radius: 10px;
	background: linear-gradient(180deg, #152a31 0%, #000 102.48%);
	padding: 60px 40px;
}

.contact_us_page .contact_us_page_form .contact_us_page_form_div.driver {
	background: #152a31;
}

.contact_us_page
	.contact_us_page_form
	.contact_us_page_form_div.driver
	.contact_us_page_form_input
	input,
.contact_us_page
	.contact_us_page_form
	.contact_us_page_form_div.driver
	.contact_us_page_form_input
	textarea {
	background: #223f49;
}

.contact_us_page
	.contact_us_page_form
	.contact_us_page_form_div
	.contact_us_page_form_title {
	color: #fff;
	font-family: Montserrat;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%;
	padding-bottom: 30px;
}

.contact_us_page
	.contact_us_page_form
	.contact_us_page_form_div
	.contact_us_page_form_input
	p {
	color: #fff;
	font-family: Montserrat;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%;
	padding-bottom: 8px;
}

.contact_us_page
	.contact_us_page_form
	.contact_us_page_form_div
	.contact_us_page_form_input
	input {
	border-radius: 5px;
	background: #152a31;
	width: 100%;
	outline: 0;
	border: 0;
	min-height: 46px;
	color: #fff;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	padding: 0 15px;
}

.contact_us_page .contact_us_page_form .contact_us_page_form_div .radio_input {
	width: 1rem;
	height: 1rem;
	cursor: pointer;
	margin: 1rem;
	margin-left: 0;
	margin-right: 0.5rem;
}
.contact_us_page .contact_us_page_form .contact_us_page_form_div label {
	color: white;
	font-size: 14px;
	margin-right: 2rem;
	cursor: pointer;
	font-family: Montserrat;
}
.contact_us_page
	.contact_us_page_form
	.contact_us_page_form_div
	.contact_us_page_form_input
	input::placeholder {
	color: #fff;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
}

.contact_us_page
	.contact_us_page_form
	.contact_us_page_form_div
	.contact_us_page_form_input
	textarea {
	border-radius: 5px;
	background: #152a31;
	width: 100%;
	outline: 0;
	border: 0;
	color: #fff;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	padding: 15px;
	resize: none;
	min-height: 100px;
}
.contact_us_page_form_div .radio_selector_heading {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	line-height: 160%;
	padding-bottom: 8px;
	color: white;
	font-size: 14px;
}
.contact_us_page
	.contact_us_page_form
	.contact_us_page_form_div
	.contact_us_page_form_input
	textarea::placeholder {
	color: #fff;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
}

.contact_us_page .contact_us_page_form .contact_us_page_form_div button {
	border-radius: 5px;
	background: linear-gradient(46deg, #0092bd 0.24%, #00c67a 97.74%);
	width: 100%;
	color: #fff;
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 140%;
	outline: 0;
	border: 0;
	min-height: 46px;
}

.contact_us_page .pc {
	display: block;
}
.contact_us_page .pc > div {
	display: flex;
	flex-direction: row;
	gap: 2rem;
	margin-bottom: 3rem;
}

.contact_us_page .mobile {
	display: none;
}

.contact_us_page .contact_us_page_form {
	padding-top: 0px;
}

.contact_us_page .contact_us_page_desc_contact_div_mobile td {
	padding-top: 20px;
	padding-right: 30px;
}

.contact_us_page
	.contact_us_page_desc_contact_div_mobile
	.contact_us_page_desc_body {
	color: #000;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	border-bottom: 1px solid #000;
	width: fit-content;
	padding-bottom: 3px;
	padding-top: 50px;
	margin: 0;
}

.contact_us_page
	.contact_us_page_desc_contact_div_mobile
	.contact_us_page_desc_contact_div {
	display: flex;
	align-items: center;
	gap: 30px;
	padding-top: 20px;
}

.contact_us_page
	.contact_us_page_desc_contact_div_mobile
	.contact_us_page_desc_contact_div
	p {
	margin: 0;
	line-break: anywhere;
}

.contact_us_page
	.contact_us_page_desc_contact_div_mobile
	.contact_us_page_desc_contact_div
	.contact_us_page_desc_contact_icon {
	display: flex;
	align-items: center;
	gap: 15px;
}

.contact_us_page
	.contact_us_page_desc_contact_div_mobile
	.contact_us_page_desc_contact_div
	.contact_us_page_desc_contact_icon
	img {
	width: 20px;
}

.contact_us_page
	.contact_us_page_desc_contact_div_mobile
	.contact_us_page_desc_contact_div
	.contact_us_page_desc_contact_info
	p:first-child {
	padding: 0;
}

.contact_us_page
	.contact_us_page_desc_contact_div_mobile
	.contact_us_page_desc_contact_div
	.contact_us_page_desc_contact_info
	p {
	color: #000;
	font-family: Montserrat;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	padding-top: 15px;
}

@media only screen and (max-width: 880px) {
	.contact_us_page {
		display: block;
		padding: 50px 70px 30px 100px;
	}

	.contact_us_page .contact_us_page_desc {
		width: 100%;
	}

	.contact_us_page .contact_us_page_desc .contact_us_page_desc_head {
		font-size: 30px;
	}

	.contact_us_page .contact_us_page_desc .contact_us_page_desc_desc {
		font-size: 13px;
		padding-top: 15px;
	}

	.contact_us_page .pc {
		display: none;
	}

	.contact_us_page .mobile {
		display: block;
	}

	.contact_us_page .contact_us_page_form {
		padding-top: 60px;
	}
}

@media only screen and (max-width: 768px) {
	.contact_us_page {
		display: block;
		padding: 20px 20px 40px;
		justify-content: space-between;
	}
}
